.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.ant-input-number-handler-wrap {
  display: none;
}

.editor-wrapper {
  height: 250px;
  max-height: 600px;
}
.editor-main {
}

.add-button-div {
  display: flex !important;
  justify-content: end;
}

.MenuItemCustom:hover {
  background-color: #fafafa;
}

.badge {
  display: inline-block;
  padding: 0.5em 0.85em;
  font-size: 0.85rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.475rem;
}

.badge-light-success {
  color: #50cd89;
  background-color: #e8fff3;
}
.badge-light-danger {
  color: #f1416c;
  background-color: #fff5f8;
}

.badge-light-info {
  color: #7239ea;
  background-color: #f8f5ff;
}
.badge-light-primary {
  color: #009ef7;
  background-color: #f1faff;
}
.badge-light-warning {
  color: #ffc700;
  background-color: #fff8dd;
}

.info-table {
}
.info-table tr {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #eff2f5;
}
.info-table > tr > td:nth-child(1) {
  color: #a1a5b7 !important;
}
.info-table > tr > td:nth-child(2) {
  font-weight: bold;
}




.invoice-header {
  display: flex;
  justify-content: space-between;
  background-color: #f5f8fa;
  padding: 10px;
  align-items: center;
}
.invoice-border {
  border: 1px solid #ddd !important;
  padding: 15px;
}